import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/Seo';
import StructuredData from '../components/structured-data'
import CardKbPost from '../components/cards/card-kb-post'
import SubscribeWidget from '../components/subscribe-widget/subscribe-widget'
import CategoryWidget from '../components/category-widget/category-widget'
import QuestionsWidget from '../components/questions-widget/questions-widget'
import KBPagination from '../components/pagination/kb-pagination'
import SearchForm from '../components/search/search-form'

const KBSeriesTemplate: React.FC<KBSeriesTemplateProps> = ({
  data,
}) => {
  const {
    series,
    posts: { nodes, pageInfo },
  } = data

  const { hasNextPage, hasPreviousPage, currentPage, pageCount } = pageInfo

  const post = {
    ...series,
    slug: series.uri.endsWith('/')
      ? series.uri.slice(0, -1).replace('/blog/', '/kb/')
      : series.uri.replace('/blog/', '/kb/'),
  }

  return (
    <Layout>
      <Seo post={post} />
      <StructuredData pageType="kbSeries" post={post} />
      <SearchForm kb={true} />
      <div className="container grid my-5 lg:grid-cols-12 lg:gap-x-20">
        <div className="lg:col-span-7 xl:col-span-8 pb-2">
          <div
            className="relative pb-2 mb-8 border-b"
            style={{ borderBottomColor: '#d9d9d9' }}
          >
            <div className="relative mt-4 z-20 knowledgeBaseTextColor">
              <h1
                dangerouslySetInnerHTML={{
                  __html: `Series: ${series.name}`,
                }}
              />
              {series.description && (
                <p
                  className="mb-4"
                  dangerouslySetInnerHTML={{
                    __html: series.description,
                  }}
                />
              )}
            </div>
          </div>
          {nodes &&
            nodes.map(node => {
              return <CardKbPost key={node.id} {...node} />
            })}
          <KBPagination
            page={series.uri.replace('/blog', '/kb')}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            currentPage={currentPage}
            pageCount={pageCount}
          />
        </div>

        <div className="lg:col-span-5 xl:col-span-4 my-4">
          <div className="p-4" style={{ backgroundColor: '#f4f4f4' }}>
            <SubscribeWidget className="knowledgeBase" isKB />
            <CategoryWidget className="mt-10" knowledgeBase={true} />
            <QuestionsWidget className="my-10" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

type KBSeriesTemplateProps = {
  location: Record<string, unknown>
  data: {
    series: {
      id: string
      name: string
      uri: string
      description: string
      seo: any
    }
    posts: {
      nodes: Array<{
        id: string
        title: string
        uri: string
        date: string
        modified: string
        readingTime
        author: {
          node: {
            name
            uri
          }
        }
        lwContent: {
          isGutenberg: boolean
          content?: string
        }
        categories: {
          nodes?: Array<{
            name: string
            uri: string
          }>
        }
        tags: {
          nodes?: Array<{
            name: string
            uri: string
          }>
        }
      }>
      pageInfo: {
        hasNextPage: boolean
        hasPreviousPage: boolean
        currentPage: number
        pageCount: number
      }
    }
  }
}

export const pageQuery = graphql`
  query GET_KB_SERIES($id: String!, $offset: Int!, $perPage: Int!) {
    series: wpSeries(id: { eq: $id }) {
      id
      name
      uri
      description
      seo {
        title
        metaDesc
      }
    }
    posts: allWpKnowledgeBase(
      filter: { seriesAll: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $perPage
      skip: $offset
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        id
        title
        uri
        date
        modified
        readingTime
        author {
          node {
            name
            uri
          }
        }
        lwContent {
          isGutenberg
          content
        }
        categories: knowledgeBaseCategories {
          nodes {
            name
            uri
          }
        }
        tags: knowledgeBaseTags {
          nodes {
            name
            uri
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`

export default KBSeriesTemplate
